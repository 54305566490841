<template>
  <YscModal
    id="supersizing-modal"
    is-header-left-on-mobile
    @close="$emit('close')"
  >
    <AppSupersizingImagesHeader />
    <!-- eslint-disable vue/no-v-html -->
    <p
      class="mt-6 text-center"
      v-html="$t('components.app_search_supersizing_banner.modal.description_html')"
    />
    <!-- eslint-enable vue/no-v-html -->
  </YscModal>
</template>

<style>
#supersizing-modal div:first-child {
  @apply w-96;
}
</style>
